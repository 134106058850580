.company-header {
  img {
    height: 70px;
  }
  .company-info {
    font-size: 12px;
    p {
      margin-bottom: 0;
    }
  }
}

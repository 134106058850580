.nav-bar-component {
  min-height: 66px;
  a.active {
    color: $primary!important;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: -($spacer * 1.58);
      left: 0;
      display: block;
      width: 100%;
      height: 4px;
      background-color: $primary;
    }
  }
}
.reports {
  .reports-container {
    .report {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid $gray-300;
      padding: $spacer;
      .title {
        display: inline-block;
        color: $gray-700;
        margin: 0;
        margin-right: $spacer * 2;
      }
    }
  }
}

.monthly-orders-report {
  table {
    td,
    th {
      width: 33.33%;
    }
  }
}

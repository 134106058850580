.system-form {
  height: 100%;
  .form-container {
    flex-grow: 1;
    position: relative;
    .fixed {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow-y: scroll;
    }
  }
  #drawing-container {
    border: 2px dashed $primary;
    background-color: white;
    // temp fix
    position: relative;
    svg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

.print-page {
  background-color: white;
  box-shadow: 0 0 40px -16px $dark;
  margin-bottom: 40px;
  padding: 40px;
  page-break-after: always;
  &.letter {
    width: 8.5in;
    height: 11in;
  }
  &.label {
    width: 4in;
    height: 6in;
  }
}

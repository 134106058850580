$primary: #7754d1;
$light: #f5f5f5;

// buttons
$btn-border-radius: 24px;
$btn-border-radius-lg: 30px;
$btn-border-radius-sm: 21px;
$padding-horizontal: 50px;
$btn-padding-x: 16px;
$btn-padding-x-lg: 22px;
$btn-padding-x-sm: 10px;

// links
$link-hover-decoration: none;

$theme-colors: (
  "light-green": #ebfbee
);

.search-box {
  position: relative;
  .fa-search {
    position: absolute;
    top: 7px;
    left: 8px;
  }
  input {
    padding-left: 30px;
    &:focus {
      & + .fa-search {
        color: $primary;
      }
    }
  }
}

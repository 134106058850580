html,
body {
  height: 100%;
}
body {
  background-color: $light;
  #root {
    height: 100%;
  }
}
label {
  color: $text-muted;
  font-weight: $font-weight-bold;
}

.App {
  border-top: 4px solid map-get($grays, '400');
  // main.content {
  //   overflow-y: auto;
  // }
}

.table-hover > tbody > tr:not(.uneditable) {
  cursor: pointer;
  &:hover {
    background-color: lighten($primary, 35);
  }
}

table.nonfluid {
  width: auto !important;
}

.nav {
  .nav-link.active {
    text-decoration: underline;
  }
}

.full-width-datepicker {
  .react-datepicker-wrapper {
    width: 100%;
  }
}

label.read-only {
  pointer-events: none;
}
.form-control[readonly] {
  pointer-events: none;
}

fieldset {
  border: 1px dashed $primary;
  padding: $spacer * 0.5;
  margin-bottom: $spacer;
  legend {
    display: inline-block;
    width: auto;
    font-size: 1.2rem;
    font-weight: bold;
    color: $gray-700;
    padding: 0 $spacer * 0.5;
    margin-left: -$spacer * 0.5;
  }
  .form-group:last-child {
    margin-bottom: 0;
  }
  label:last-child {
    margin-bottom: 0 !important;
  }
}

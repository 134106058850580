.company-logo-component {
  .logo-container {
    margin-top: 40px;
    width: 200px;
    height: 200px;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px $border-color solid;
    border-radius: 6px;
    &:hover {
      label {
        display: block;
      }
    }
    input[type='file'] {
      display: none;
    }
    label {
      display: none;
      width: 100%;
      height: 34px;
      line-height: 34px;
      position: absolute;
      bottom: 0;
      margin: 0;
      background-color: transparentize(black, 0.4);
      color: white;
      border-radius: 0 0 6px 6px;
      text-align: center;
      cursor: pointer;
    }
  }
}

.type-button,
.print-button,
.close-button {
  position: fixed;
  bottom: 110px;
  left: 10px;
  z-index: 10;
  width: 50px;
}
.print-button {
  bottom: 60px;
}
.close-button {
  bottom: 10px;
}

.pages-container {
  width: 100%;
  .print-page {
    .table {
      td,
      th {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .system-image-container {
      position: relative;
      border: 1px solid $table-border-color;
      border-top: 0;

      width: 100%;
      height: 300px;
      padding: 20px;
      &.small {
        height: 220px;
      }
      img {
        width: 100%;
        height: 100%;
      }
      .top-left-message,
      .top-right-message {
        position: absolute;
        top: 2px;
        font-size: 10px;
      }
      .top-left-message {
        left: 6px;
        color: $danger;
      }
      .top-right-message {
        right: 6px;
      }
    }
    .line-price {
      tbody {
        tr:first-child {
          th,
          td {
            border-top: 0;
          }
        }
      }
    }
    .cut-list,
    .glass-list,
    .screen-list {
      border-top: 0;
      thead {
        th {
          border-top: 0;
        }
      }
      tr.heading th {
        border-bottom-width: 2px;
      }
    }
    .glass-list,
    .screen-list {
      margin-bottom: 0;
    }
    .glass-list .glass-composition-header {
      width: 150px;
      max-width: 100px;
    }
  }

  .print-page.label {
    h6 {
      font-size: 0.8rem;
    }
    .header {
      height: 25px;
      background-color: darken($border-color, 5);
      border-bottom: darken($border-color, 5);
      justify-content: center;
      align-items: center;
      font-weight: bold;
    }
    &.glass {
      h6 {
        font-size: 1rem;
      }
    }
  }
}

@media print {
  @page {
    size: 8.5in 11in;
  }
  body {
    min-height: 0 !important;
    min-width: 0 !important;
  }
  .actions {
    display: none;
  }
  .print-page {
    box-shadow: none;
    margin: 0 !important;
    &.label {
      * {
        border-color: $gray-600 !important;
      }
    }
  }
}

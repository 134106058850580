.estimates-pagination {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  .pagination {
    margin-bottom: 0;
  }
}

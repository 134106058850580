.users-component .header a {
  color: $text-muted;
  &:first-child {
    margin-right: $spacer * 0.5;
  }
  &:last-child {
    margin-left: $spacer * 0.5;
  }
  &.active {
    color: $body-color;
  }
}

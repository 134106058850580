.signin-container,
.signup-container,
.forgot-password-container,
.reset-password-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .signin-form,
  .signup-form,
  .forgot-password-form,
  .reset-password-form {
    position: relative;
    background-color: white;
    border: 4px solid $primary;
    box-shadow: $box-shadow;
    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 50px;
      width: 50px;
      background-color: white;
    }
    &:before {
      bottom: -4px;
      left: -4px;
    }
    &:after {
      top: -4px;
      right: -4px;
    }
    .content {
      position: relative;
      z-index: 10;
      .input-container {
        &:first-child {
          border-bottom: 1px solid $input-border-color;
          input {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: 0;
          }
        }
        &:nth-child(2) {
          input {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: 0;
          }
        }
      }
      .input-container {
        position: relative;
        input {
          &:focus {
            box-shadow: none;
            & + svg {
              color: $primary;
            }
          }
          padding-left: 42px;
        }
        svg {
          position: absolute;
          left: 12px;
          top: 8px;
          color: map-get($grays, '600');
        }
      }
    }
  }
  .forgot-password-form {
    max-width: 350px;
    .content {
      .input-container {
        &:first-child {
          border-bottom: none;
          input {
            border-bottom: 1px solid $input-border-color;
            border-bottom-left-radius: $input-border-radius;
            border-bottom-right-radius: $input-border-radius;
          }
        }
      }
    }
  }
  .reset-password-form {
    max-width: 350px;
  }
  .powered-by {
    position: absolute;
    left: 20px;
    bottom: 20px;
    img {
      display: inline-block;
      margin-right: 20px;
    }
    h6 {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      margin: 0;
    }
  }
}
